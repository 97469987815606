import React, { useContext } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { FaXTwitter } from "react-icons/fa6";

import Logo from "../Logo";
const Footer = () => {
  const gContext = useContext(GlobalContext);
  const linkClassName =
    gContext.footer.theme === "dark"
      ? "gr-hover-text-green"
      : "gr-hover-text-blue";
  const iconClassName =
    gContext.footer.theme === "dark"
      ? "text-storm gr-hover-text-green"
      : "gr-hover-text-blue";
  return (
    <>
      <div
        className={`footer-section pt-15 pt-lg-12 pb-lg-5 ${
          gContext.footer.theme === "dark"
            ? "dark-mode-texts bg-blackish-blue"
            : ""
        }`}
      >
        <Container>
          <Row className="justifyContent-center">
            <Col lg="12" md="8" className="offset-lg-1">
              <Row>
                <Col xs="8" lg="3">
                  <div className="single-footer mb-13 mb-lg-9">
                   <p className="footer-title fw-bold" style={{"fontSize":  "18px", "color": "#fff"}}>About</p>
                    <ul className="footer-list list-unstyled gr-text-15">
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/about" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          About us
                        </Link>
                      </li>
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/contact" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Contact us
                        </Link>
                      </li>
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/job-openings" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Career
                        </Link>
                      </li>
                      <br></br>
                      <li style={{"margin-bottom": "3px"}}>
                        <div to="/job-openings" className="gr-text-11 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                         UEI SAM: RYUJXHF9W6B9
                        </div>
                      </li>
                      <li style={{"margin-bottom": "3px"}}>
                        <div to="/job-openings" className="gr-text-11 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          CAGE: 9KL56
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs="8" lg="4">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p className="footer-title fw-bold" style={{"fontSize":  "18px", "color": "#fff"}}>Services</p>
                    <ul className="footer-list list-unstyled gr-text-15">
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/devsecops" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Agile delivery and DevSecOps
                        </Link>
                      </li>
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/infrastructure-automation" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Cloud Infrastructure Automation 
                        </Link>
                      </li>
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/microservices" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Microservices Architectures and Containerization
                        </Link>
                      </li>
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/data-integration" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Application and Data Integration
                        </Link>
                      </li>
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/ai-ml" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Artificial Intelligence & Machine Learning
                        </Link>
                      </li>                      
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/training" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Training and Consulting
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs="8" lg="4">
                  <div className="single-footer mb-13 mb-lg-9">
                   <p className="footer-title fw-bold" style={{"fontSize":  "18px", "color": "#fff"}}>Solutions</p>
                    <ul className="footer-list list-unstyled gr-text-15">
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/modernization" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Federal IT modernization
                        </Link>
                      </li>
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/get-ai" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Generative AI
                        </Link>
                      </li>
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/cybersecurity" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Cybersecurity
                        </Link>
                      </li>
                      <li style={{"margin-bottom": "3px"}}>
                        <Link to="/data-analytics" className="gr-text-10 mb-0" style={{"color": "rgba(255,255,255,0.7)"}}>
                          Data and analytics
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
            <hr style={{"color": "#fff"}}/>
            <Row>
              <Col lg="12" md="8" style={{"display": "flex", "alignItems": "center", "justifyContent": "center"}} >
                <ul className="social-icons  list-unstyled mb-7 mb-lg-0 ">
                  <li className="me-2">
                    <Link to="https://twitter.com/ain_intel" className={iconClassName}>
                      <i className="icon"><FaXTwitter /></i>
                    </Link>
                  </li>
                  <li className="me-2">
                    <Link to="https://www.linkedin.com/company/ainintel" className={iconClassName}>
                      <i className="icon icon-logo-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </Col>
               <Col lg="12" md="8" style={{"display": "flex", "alignItems": "center", "justifyContent": "center"}} >
                 <p className="gr-text-11 text-color-opacity" style={{"color": "#666"}} >
                  Copyright  {new Date().getFullYear()} AIN, LLC
                </p>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Footer;
