export const menuItems = [
  {
    name: "services",
    label: "Services",
    items: [
      { name: "devsecops", label: "Agile delivery and DevSecOps" },
      { name: "infrastructure-automation", label: "Cloud Infrastructure Automation" },
      {
        name: "microservices",
        label: "Microservices Architectures and Containerization",
      },
      {
        name: "data-integration",
        label: "Application and Data Integration",
      },
      { name: "ai-ml", label: "Artificial Intelligence & Machine Learning" },
      { name: "training", label: "Training and Consulting" },
    ],
  },
  {
    name: "solutions",
    label: "Solutions",
    items: [
      { name: "modernization", label: "Federal IT modernization" },
      { name: "get-ai", label: "Generative AI" },
      { name: "cybersecurity", label: "Cybersecurity" },
      { name: "data-analytics", label: "Data and analytics" },
    ],
  },
  {
    name: "about",
    label: "About",
  },
  {
    name: "job-openings",
    label: "Career",
  },
];


